<template>
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
    <div style="background-color: white">
      <div class="box">
        <el-row class="row">
          <el-col :span="8">
              <el-form-item prop="clockContent1">
                <template #label><span class="title">提醒内容</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.clockContent1" maxlength="20" show-word-limit clearable></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.clockContent1}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="6">
              <el-form-item>
                <template #label><span class="title">提醒时间</span></template>
                <el-time-picker arrow-control v-if="edit == true" v-model="time1" format="HH:mm" placeholder="提醒时间" class="input" style="width:100%"></el-time-picker>
                <span v-show="edit == false" class="content">{{ruleForm.time1}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="5">
              <el-form-item>
                <template #label><span class="title">响铃方式</span></template>
                <el-select v-if="edit == true" v-model="ruleForm.clockType1" style="margin-left:5px;" placeholder="响铃方式" size="large" clearable>
                  <el-option v-for="item in modeOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <!-- <span v-if="ruleForm.clockType1 == 0" v-show="edit == false" class="content">单次</span> -->
                <span v-if="ruleForm.clockType1 == 1" v-show="edit == false" class="content">每天</span>
                <span v-if="ruleForm.clockType1 == 2" v-show="edit == false" class="content">工作日</span>
              </el-form-item>
          </el-col>
          <el-col :span="5">
              <el-form-item>
                <template #label><span class="title">闹铃开关</span></template>
                <el-switch v-if="edit == true" v-model="clockState1" active-text="开" inactive-text="关"></el-switch>
                <span v-if="ruleForm.clockState1 == 1" v-show="edit == false" class="content">已开启</span>
                <span v-if="ruleForm.clockState1 == 0" v-show="edit == false" class="content">已关闭</span>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="8">
              <el-form-item prop="clockContent2">
                <template #label><span class="title">提醒内容</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.clockContent2" maxlength="20" show-word-limit clearable></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.clockContent2}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="6">
              <el-form-item>
                <template #label><span class="title">提醒时间</span></template>
                <el-time-picker arrow-control v-if="edit == true" v-model="time2" format="HH:mm" placeholder="提醒时间" class="input" style="width:100%"></el-time-picker>
                <span v-show="edit == false" class="content">{{ruleForm.time2}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="5">
              <el-form-item>
                <template #label><span class="title">响铃方式</span></template>
                <el-select v-if="edit == true" v-model="ruleForm.clockType2" style="margin-left:5px;" placeholder="响铃方式" size="large" clearable>
                  <el-option v-for="item in modeOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <!-- <span v-if="ruleForm.clockType2 == 0" v-show="edit == false" class="content">单次</span> -->
                <span v-if="ruleForm.clockType2 == 1" v-show="edit == false" class="content">每天</span>
                <span v-if="ruleForm.clockType2 == 2" v-show="edit == false" class="content">工作日</span>
              </el-form-item>
          </el-col>
          <el-col :span="5">
              <el-form-item>
                <template #label><span class="title">闹铃开关</span></template>
                <el-switch v-if="edit == true" v-model="clockState2" active-text="开" inactive-text="关"></el-switch>
                <span v-if="ruleForm.clockState2 == 1" v-show="edit == false" class="content">已开启</span>
                <span v-if="ruleForm.clockState2 == 0" v-show="edit == false" class="content">已关闭</span>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
            <el-col :span="8">
              <el-form-item prop="clockContent3">
                <template #label><span class="title">提醒内容</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.clockContent3" maxlength="20" show-word-limit clearable></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.clockContent3}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="6">
              <el-form-item>
                <template #label><span class="title">提醒时间</span></template>
                <el-time-picker arrow-control v-if="edit == true" v-model="time3" format="HH:mm" placeholder="提醒时间" class="input" style="width:100%"></el-time-picker>
                <span v-show="edit == false" class="content">{{ruleForm.time3}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="5">
              <el-form-item>
                <template #label><span class="title">响铃方式</span></template>
                <el-select v-if="edit == true" v-model="ruleForm.clockType3" style="margin-left:5px;" placeholder="响铃方式" size="large" clearable>
                  <el-option v-for="item in modeOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <!-- <span v-if="ruleForm.clockType3 == 0" v-show="edit == false" class="content">单次</span> -->
                <span v-if="ruleForm.clockType3 == 1" v-show="edit == false" class="content">每天</span>
                <span v-if="ruleForm.clockType3 == 2" v-show="edit == false" class="content">工作日</span>
              </el-form-item>
          </el-col>
          <el-col :span="5">
              <el-form-item>
                <template #label><span class="title">闹铃开关</span></template>
                <el-switch v-if="edit == true" v-model="clockState3" active-text="开" inactive-text="关"></el-switch>
                <span v-if="ruleForm.clockState3 == 1" v-show="edit == false" class="content">已开启</span>
                <span v-if="ruleForm.clockState3 == 0" v-show="edit == false" class="content">已关闭</span>
              </el-form-item>
          </el-col>
        </el-row>


        <el-row class="row">
          <el-col :span="24">
            <el-form-item>
              <el-button v-show="(edit == false)&&(this.role != 1)" type="primary" size="small" @click="editView" >编辑</el-button>
              <el-button v-show="edit == true" type="primary" size="small"  @click="edit = false" plain >取消</el-button>
              <el-button type="primary"  size="small" v-show="edit == true" @click="submitForm('ruleForm')">提交</el-button>
            </el-form-item>
          </el-col>
        </el-row>

      </div>
    </div>
  </el-form>

</template>

<script>
import {
  editSmartHomeSetInfo
} from '../../../api/api'
import session from "../../../store/store";
export default {
  data() {
    return {
      edit: false,
      role: session.getLoginInfo().accountType,
      time1:'',
      time2:'',
      time3:'',
      clockState1:false,
      clockState2:false,
      clockState3:false,

      ruleForm: {
        time1:'',
        time2:'',
        time3:'',
      },
      rules: {
        clockContent1:[
          {required: true,min: 0,max: 11,message: "长度在 0 到 20 个汉字", trigger: "blur"},
          {pattern: /^[\u4E00-\u9FA5]+$/, message: "只能输入汉字", trigger: 'blur' }
        ],
        clockContent2:[
          {required: true,min: 0,max: 11,message: "长度在 0 到 20 个汉字", trigger: "blur"},
          {pattern: /^[\u4E00-\u9FA5]+$/, message: "只能输入汉字", trigger: 'blur' }
        ],
        clockContent3:[
          {required: true,min: 0,max: 11,message: "长度在 0 到 20 个汉字", trigger: "blur"},
          {pattern: /^[\u4E00-\u9FA5]+$/, message: "只能输入汉字", trigger: 'blur' }
        ],
      },
      modeOption:[
        // {value: 0,label: '单次'},
        {value: '1',label: '每天'},
        {value: '2',label: '工作日'},
      ],
      deviceData:{},
    };
  },
  mounted() {
    // this.init();
    
  },
  methods: {
    getParentData(data,deviceData) {
      this.required(false)
      this.ruleForm = data  
      this.deviceData = deviceData    
      // console.log(data)
      this.ruleForm.time1 = this.concatZero(data.clockHour1) + ":" + this.concatZero(data.clockMinute1) 
      this.ruleForm.time2 = this.concatZero(data.clockHour2) + ":" + this.concatZero(data.clockMinute2) 
      this.ruleForm.time3 = this.concatZero(data.clockHour3) + ":" + this.concatZero(data.clockMinute3) 
      this.time1 = new Date()
      this.time1.setHours(data.clockHour1)
      this.time1.setMinutes(data.clockMinute1)

      this.time2 = new Date()
      this.time2.setHours(data.clockHour2)
      this.time2.setMinutes(data.clockMinute2)

      this.time3 = new Date()
      this.time3.setHours(data.clockHour3)
      this.time3.setMinutes(data.clockMinute3)

      if(data.clockState1 == 1) {
        this.clockState1 = true
      }else {
        this.clockState1 = false
      }
      if(data.clockState2 == 1) {
        this.clockState2 = true
      }else {
        this.clockState2 = false
      }
      if(data.clockState3 == 1) {
        this.clockState3 = true
      }else {
        this.clockState3 = false
      }

    },

    concatZero(time) {
      var data = "00"
      if(time < 10) {
        data = '0' + time
      }else {
        data = time
      }
      return data
    },

    required(yes) {
      this.rules.clockContent1[0].required = yes;
      this.rules.clockContent2[0].required = yes;
      this.rules.clockContent3[0].required = yes;
    },

    submitForm(ruleName) {
      this.ruleForm.deviceId = this.deviceData.deviceId
      this.ruleForm.terminal = 1
      this.ruleForm.unitUuid = session.getLoginInfo().unitUuid
      this.ruleForm.isDoor = 0
      this.ruleForm.deviceSubId = this.deviceData.deviceSubId,
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          this.editSmartHomeSetInfo();
        } else {
          return false;
        }
      });
    },
    editSmartHomeSetInfo() {
      this.ruleForm.clockHour1 = this.time1.getHours()
      this.ruleForm.clockMinute1 = this.time1.getMinutes()

      this.ruleForm.clockHour2 = this.time2.getHours()
      this.ruleForm.clockMinute2 = this.time2.getMinutes()

      this.ruleForm.clockHour3 = this.time3.getHours()
      this.ruleForm.clockMinute3 = this.time3.getMinutes()

      if(this.clockState1 == true) {
        this.ruleForm.clockState1 = 1
      }else {
        this.ruleForm.clockState1 = 0
      }
      if(this.clockState2 == true) {
        this.ruleForm.clockState2 = 1
      }else {
        this.ruleForm.clockState2 = 0
      }
      if(this.clockState3 == true) {
        this.ruleForm.clockState3 = 1
      }else {
        this.ruleForm.clockState3 = 0
      }

      this.ruleForm.func = 'alarm'
      editSmartHomeSetInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '修改成功',  type: 'success'});
          this.closeEdit();
          this.getParentData(this.ruleForm)
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },
    editView() {
      this.required(true)
      this.edit = true
    },
    closeEdit() {
      this.edit = false
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 90%;
}
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.title {
  margin-right: 5px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  width: 100%;
  // padding: 20px 20px;
  background-color: white;
  border-radius: 4px;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}


</style>

