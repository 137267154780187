<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules"  label-width="160px" class="demo-ruleForm" >
      <div style="background-color: white">
        <div class="box">
          
          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="name">
                  <template #label><span class="title">预警总开关</span></template>
                  <el-switch v-if="edit == true" v-model="this.deviceData.switchOn" active-text="开" inactive-text="关"></el-switch>
                  <span v-show="edit == false" class="content" v-if="this.ruleForm.switchOn == 1">开启</span>
                  <span v-show="edit == false" class="content" v-if="this.ruleForm.switchOn == 0">关闭</span>
                </el-form-item>
            </el-col>
          </el-row>

          <div v-if="this.isDoor == 1">
            <hr />

            <el-row class="row">
              <el-col :span="24">
                  <el-form-item prop="name">
                    <template #label><span class="title">开门预警</span></template>
                    <el-switch v-if="edit == true" v-model="this.deviceData.openSwitchOn" active-text="开" inactive-text="关"></el-switch>
                    <span v-show="edit == false" class="content" v-if="this.ruleForm.openSwitchOn == 1">开启</span>
                    <span v-show="edit == false" class="content" v-if="this.ruleForm.openSwitchOn == 0">关闭</span>
                  </el-form-item>
              </el-col>
            </el-row>

            <el-row class="row">
              <el-col :span="12">
                  <el-form-item prop="name">
                    <template #label><span class="title">开门时长预警</span></template>
                    <el-switch v-if="edit == true" v-model="this.deviceData.openDurationOn" active-text="开" inactive-text="关"></el-switch>
                    <span v-show="edit == false" class="content" v-if="this.ruleForm.openDurationOn == 1">开启</span>
                    <span v-show="edit == false" class="content" v-if="this.ruleForm.openDurationOn == 0">关闭</span>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item prop="name">
                    <template #label><span class="title">开门时长设置</span></template>
                    <el-input v-show="edit == true" v-model="this.deviceData.openDurationTime" type="number" placeholder="请输入开门时长" class="input">
                      <template #append>分钟</template>
                    </el-input>
                    <span v-show="edit == false" class="content">{{this.deviceData.openDurationTime}}分钟</span>
                  </el-form-item>
              </el-col>
            </el-row>

            <el-row class="row">
              <el-col :span="12">
                  <el-form-item prop="name">
                    <template #label><span class="title">开门时间段预警</span></template>
                    <el-switch v-if="edit == true" v-model="this.deviceData.openTimeSlotSwitchOn" active-text="开" inactive-text="关"></el-switch>
                    <span v-show="edit == false" class="content" v-if="this.ruleForm.openTimeSlotSwitchOn == 1">开启</span>
                    <span v-show="edit == false" class="content" v-if="this.ruleForm.openTimeSlotSwitchOn == 0">关闭</span>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item prop="name">
                    <template #label><span class="title">开门时间段设置</span></template>
                    <el-time-picker v-if="edit == true"  v-model="this.deviceData.openTime" is-range range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" format="HH:mm" value-format="HH:mm" style="width:90%" />
                    <span v-show="edit == false" class="content">{{this.deviceData.openBeginEndTime}}</span>
                  </el-form-item>
              </el-col>
            </el-row>

            <hr />
            <el-row class="row">
              <el-col :span="24">
                  <el-form-item prop="name">
                    <template #label><span class="title">关门预警开关</span></template>
                    <el-switch v-if="edit == true" v-model="this.deviceData.closeSwitchOn" active-text="开" inactive-text="关"></el-switch>
                    <span v-show="edit == false" class="content" v-if="this.ruleForm.closeSwitchOn == 1">开启</span>
                    <span v-show="edit == false" class="content" v-if="this.ruleForm.closeSwitchOn == 0">关闭</span>
                  </el-form-item>
              </el-col>
            </el-row>

            <el-row class="row">
              <el-col :span="12">
                  <el-form-item prop="name">
                    <template #label><span class="title">关门时长预警</span></template>
                    <el-switch v-if="edit == true" v-model="this.deviceData.closeDurationOn" active-text="开" inactive-text="关"></el-switch>
                    <span v-show="edit == false" class="content" v-if="this.ruleForm.closeDurationOn == 1">开启</span>
                    <span v-show="edit == false" class="content" v-if="this.ruleForm.closeDurationOn == 0">关闭</span>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item prop="name">
                    <template #label><span class="title">关门时长预警</span></template>
                    <el-input v-show="edit == true" v-model="this.deviceData.closeDurationTime" type="number" placeholder="请输入开门时长" class="input">
                      <template #append>分钟</template>
                    </el-input>
                    <span v-show="edit == false" class="content">{{this.deviceData.closeDurationTime}}分钟</span>
                  </el-form-item>
              </el-col>
            </el-row>

            <el-row class="row">
              <el-col :span="12">
                  <el-form-item prop="name">
                    <template #label><span class="title">关门时间段预警</span></template>
                    <el-switch v-if="edit == true" v-model="this.deviceData.closeTimeSlotSwitchOn" active-text="开" inactive-text="关"></el-switch>
                    <span v-show="edit == false" class="content" v-if="this.ruleForm.closeTimeSlotSwitchOn == 1">开启</span>
                    <span v-show="edit == false" class="content" v-if="this.ruleForm.closeTimeSlotSwitchOn == 0">关闭</span>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item prop="name">
                    <template #label><span class="title">关门时间段设置</span></template>
                    <el-time-picker v-if="edit == true" v-model="this.deviceData.closeTime" is-range range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" format="HH:mm" value-format="HH:mm" style="width:90%" />
                    <span v-show="edit == false" class="content">{{this.deviceData.closeBeginEndTime}}</span>
                  </el-form-item>

              </el-col>
            </el-row>
          </div>

          <!-- <div v-if="this.isDoor !=1 && this.deviceData.deviceSubId == 2"> 
            <hr />
            <el-row class="row">
              <el-col :span="12">
                  <el-form-item>
                    <template #label> <span class="title">姓名1</span></template>
                    <el-input v-show="edit == true" v-model="ruleForm.name1" class="input" ></el-input>
                    <span v-show="edit == false" class="content">{{ruleForm.name1}}</span>
                  </el-form-item>
              </el-col>
              <el-col :span="12">
                  <el-form-item>
                    <template #label> <span class="title">号码1</span></template>
                    <el-input v-show="edit == true" v-model="ruleForm.tel1" class="input" ></el-input>
                    <span v-show="edit == false" class="content">{{ruleForm.tel1}}</span>
                  </el-form-item>
              </el-col>
            </el-row>

            <el-row class="row">
              <el-col :span="12">
                  <el-form-item>
                    <template #label><span class="title">姓名2</span></template>
                    <el-input v-show="edit == true" v-model="ruleForm.name2" class="input" ></el-input>
                    <span v-show="edit == false" class="content">{{ruleForm.name2}}</span>
                  </el-form-item>
              </el-col>
              <el-col :span="12">
                  <el-form-item>
                    <template #label><span class="title">号码2</span></template>
                    <el-input v-show="edit == true" v-model="ruleForm.tel2" class="input" ></el-input>
                    <span v-show="edit == false" class="content">{{ruleForm.tel2}}</span>
                  </el-form-item>
              </el-col>
            </el-row>


            <el-row class="row">
              <el-col :span="12">
                  <el-form-item>
                    <template #label><span class="title">姓名3</span></template>
                    <el-input v-show="edit == true" v-model="ruleForm.name3" class="input" ></el-input>
                    <span v-show="edit == false" class="content">{{ruleForm.name3}}</span>
                  </el-form-item>
              </el-col>
              <el-col :span="12">
                  <el-form-item>
                    <template #label><span class="title">号码3</span></template>
                    <el-input v-show="edit == true" v-model="ruleForm.tel3" class="input" ></el-input>
                    <span v-show="edit == false" class="content">{{ruleForm.tel3}}</span>
                  </el-form-item>
              </el-col>
            </el-row>

          </div> -->
          <el-row class="row">
            <el-col :span="24">
              <el-form-item>
                <el-button v-show="(edit == false)&&(this.role != 1)" type="primary" size="small" @click="edit = true" >编辑</el-button >
                <el-button v-show="edit == true" type="primary" size="small"  @click="edit = false" plain >取消</el-button>
                <el-button type="primary"  size="small" v-show="edit == true" @click="submitForm()">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>

          
        </div>
      </div>
    </el-form>
  
  </div>
</template>

<script>
import {
  getSmartHomeSetInfo,
  editSmartHomeSetInfo
} from '../../../api/api'
import session from "../../../store/store";
export default {
  data() {
    return {
      edit: false,
      role: session.getLoginInfo().accountType,
      deviceData:{},
      isDoor:0,
      ruleForm: {},
      rules: {},
      openTime:null,
      closeTime:null,
      
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
    getParentData(deviceData) {
      this.deviceData = deviceData;
      console.log(this.deviceData)
      if(this.deviceData.deviceSubId == 1) {
         this.isDoor = 1 //门磁设备
      }else {
        this.isDoor = 0 //非门磁设备
      }
      this.deviceData.openTime = []
      this.deviceData.closeTime = []
 
      this.getSmartHomeSetInfo();
    },
    getSmartHomeSetInfo() {
      let param = {
        deviceId:this.deviceData.deviceId,
        terminal:1,
        unitUuid:session.getLoginInfo().unitUuid,
        deviceSubId: this.deviceData.deviceSubId,
        isDoor: this.isDoor
      }
      getSmartHomeSetInfo(param).then(res => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.dataInfo
          this.toData(res.data.dataInfo)
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    submitForm() {
      this.editSmartHomeSetInfo()
    },

    editSmartHomeSetInfo() {
      this.ruleForm.deviceId = this.deviceData.deviceId
      this.ruleForm.terminal = 1
      this.ruleForm.unitUuid = session.getLoginInfo().unitUuid
      this.ruleForm.isDoor = this.isDoor
      this.ruleForm.deviceSubId = this.deviceData.deviceSubId,
      this.toEditData()
      editSmartHomeSetInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: "修改成功"});
          this.closeEdit();
          this.getSmartHomeSetInfo()
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    toEditData() {
      if(this.deviceData.switchOn == true) {
        this.ruleForm.switchOn = 1
      }else {
        this.ruleForm.switchOn = 0
      }
      if(this.isDoor == 1) {

        var openBeginTime = this.deviceData.openTime[0]
        var openEndTime = this.deviceData.openTime[1]

        var openBeginTimeList = openBeginTime.split(":")
        var openEndTimeList = openEndTime.split(":")
        this.ruleForm.openBeginHour = parseInt(openBeginTimeList[0])
        this.ruleForm.openBeginMinute = parseInt(openBeginTimeList[1])
        this.ruleForm.openEndHour = parseInt(openEndTimeList[0])
        this.ruleForm.openEndMinute = parseInt(openEndTimeList[1])

        var closeBeginTime = this.deviceData.closeTime[0]
        var closeEndTime = this.deviceData.closeTime[1]

        var closeBeginTimeList = closeBeginTime.split(":")
        var closeEndTimeList = closeEndTime.split(":")
        this.ruleForm.closeBeginHour = parseInt(closeBeginTimeList[0])
        this.ruleForm.closeBeginMinute = parseInt(closeBeginTimeList[1])
        this.ruleForm.closeEndHour = parseInt(closeEndTimeList[0])
        this.ruleForm.closeEndMinute = parseInt(closeEndTimeList[1])


        this.ruleForm.openDurationTime = this.deviceData.openDurationTime
        this.ruleForm.closeDurationTime = this.deviceData.closeDurationTime

        if(this.deviceData.openSwitchOn == true) {
          this.ruleForm.openSwitchOn = 1
        }else {
          this.ruleForm.openSwitchOn = 0
        }
        if(this.deviceData.openDurationOn == true) {
          this.ruleForm.openDurationOn = 1
        }else {
          this.ruleForm.openDurationOn = 0
        }
        if(this.deviceData.openTimeSlotSwitchOn == true) {
          this.ruleForm.openTimeSlotSwitchOn = 1
        }else {
          this.ruleForm.openTimeSlotSwitchOn = 0
        }
        if(this.deviceData.closeSwitchOn == true) {
          this.ruleForm.closeSwitchOn = 1
        }else {
          this.ruleForm.closeSwitchOn = 0
        }
        if(this.deviceData.closeDurationOn == true) {
          this.ruleForm.closeDurationOn = 1
        }else {
          this.ruleForm.closeDurationOn = 0
        }
        if(this.deviceData.closeTimeSlotSwitchOn == true) {
          this.ruleForm.closeTimeSlotSwitchOn = 1
        }else {
          this.ruleForm.closeTimeSlotSwitchOn = 0
        }
      }

    },

    toData(data) {
      if(data.switchOn == 1) {
        this.deviceData.switchOn = true
      }else {
        this.deviceData.switchOn = false
      }
      if(this.isDoor == 1) {
        var openBeginHour = this.concatZero(data.openBeginHour);
        var openBeginMinute = this.concatZero(data.openEndMinute);
        var openCloseHour = this.concatZero(data.openEndHour);
        var openEndMinute = this.concatZero(data.openEndMinute);

        this.deviceData.openTime[0] = openBeginHour + ":" + openBeginMinute
        this.deviceData.openTime[1] = openCloseHour + ":" + openEndMinute
        
        this.deviceData.openBeginEndTime = openBeginHour + ":" + openBeginMinute + "~" + openCloseHour + ":" + openEndMinute


        var closeBeginHour = this.concatZero(data.closeBeginHour);
        var closeBeginMinute = this.concatZero(data.closeBeginMinute);
        var closeEndHour = this.concatZero(data.closeEndHour);
        var closeEndMinute = this.concatZero(data.closeEndMinute);

        this.deviceData.closeTime[0] = closeBeginHour + ":" + closeBeginMinute
        this.deviceData.closeTime[1] = closeEndHour + ":" + closeEndMinute

        this.deviceData.closeBeginEndTime = closeBeginHour + ":" + closeBeginMinute + "~" + closeEndHour + ":" + closeEndMinute

        this.deviceData.openDurationTime = data.openDurationTime
        this.deviceData.closeDurationTime = data.closeDurationTime
        if(data.openSwitchOn == 1) {
          this.deviceData.openSwitchOn = true
        }else {
          this.deviceData.openSwitchOn = false
        }
        if(data.openDurationOn == 1) {
          this.deviceData.openDurationOn = true
        }else {
          this.deviceData.openDurationOn = false
        }
        if(data.openTimeSlotSwitchOn == 1) {
          this.deviceData.openTimeSlotSwitchOn = true
        }else {
          this.deviceData.openTimeSlotSwitchOn = false
        }
        if(data.closeSwitchOn == 1) {
          this.deviceData.closeSwitchOn = true
        }else {
          this.deviceData.closeSwitchOn = false
        }
        if(data.closeDurationOn == 1) {
          this.deviceData.closeDurationOn = true
        }else {
          this.deviceData.closeDurationOn = false
        }
        if(data.closeTimeSlotSwitchOn == 1) {
          this.deviceData.closeTimeSlotSwitchOn = true
        }else {
          this.deviceData.closeTimeSlotSwitchOn = false
        }
      }
      
    },
    closeEdit() {
      this.edit = false
    },
    concatZero(time) {
      var data = "00"
      if(time < 10) {
        data = '0' + time
      }else {
        data = time
      }
      return data
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 90%;
}
hr {
  border-color: #f4f4f526;
}
.title {
  margin-right: 10px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  width: 75%;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}

</style>
