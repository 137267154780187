<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules"  label-width="160px" class="demo-ruleForm" >
      <div style="background-color: white">
        <div class="box">
          
          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="name">
                  <template #label><span class="title">预警总开关</span></template>
                  <el-switch v-if="edit == true" v-model="this.deviceData.switchOn" active-text="开" inactive-text="关"></el-switch>
                  <span v-show="edit == false" class="content" v-if="this.ruleForm.switchOn == 1">开启</span>
                  <span v-show="edit == false" class="content" v-if="this.ruleForm.switchOn == 0">关闭</span>
                </el-form-item>
            </el-col>
          </el-row>


          <el-row class="row">
            <el-col :span="24">
              <el-form-item>
                <el-button v-show="(edit == false)&&(this.role != 1)" type="primary" size="small" @click="edit = true" >编辑</el-button >
                <el-button v-show="edit == true" type="primary" size="small"  @click="edit = false" plain >取消</el-button>
                <el-button type="primary"  size="small" v-show="edit == true" @click="submitForm()">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>

          <hr />
          <div class="setTab">
            <el-tabs tab-position="top" @tab-click="handleClick">
              <el-tab-pane label="SOS监护人" >
                <SmartHomeSosGuardian ref="guardianView"></SmartHomeSosGuardian>
              </el-tab-pane>
              <el-tab-pane label="健康提醒" >
                <SmartHomeSosAlarm ref="alarmView"></SmartHomeSosAlarm>
              </el-tab-pane>
              <el-tab-pane label="AI语音">
                <SmartHomeSosNotice ref="noticeView"></SmartHomeSosNotice>
              </el-tab-pane>
            </el-tabs>
          </div>
          
        </div>
      </div>
    </el-form>
  
  </div>
</template>

<script>
import {
  getSmartHomeSetInfo,
  editSmartHomeSetInfo
} from '../../../api/api'
import session from "../../../store/store";
import SmartHomeSosAlarm from "./SmartHomeSosAlarm.vue";
import SmartHomeSosGuardian from "./SmartHomeSosGuardian.vue";
import SmartHomeSosNotice from "./SmartHomeSosNotice.vue";
export default {
  components: {
    SmartHomeSosAlarm,
    SmartHomeSosGuardian,
    SmartHomeSosNotice
  },
  data() {
    return {
      edit: false,
      role: session.getLoginInfo().accountType,
      deviceData:{},
      isDoor:0,
      ruleForm: {},
      rules: {},
      openTime:null,
      closeTime:null,
      
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
    getParentData(deviceData) {
      this.deviceData = deviceData;
      // console.log(this.deviceData)
      if(this.deviceData.deviceSubId == 1) {
         this.isDoor = 1 //门磁设备
      }else {
        this.isDoor = 0 //非门磁设备
      }

      this.getSmartHomeSetInfo();
    },
    getSmartHomeSetInfo() {
      let param = {
        deviceId:this.deviceData.deviceId,
        terminal:1,
        unitUuid:session.getLoginInfo().unitUuid,
        deviceSubId: this.deviceData.deviceSubId,
        isDoor: this.isDoor
      }
      getSmartHomeSetInfo(param).then(res => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.dataInfo
          this.toData(res.data.dataInfo)

          setTimeout(() => {
            this.$refs.guardianView.getParentData(this.ruleForm,this.deviceData);
          }, 200);
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    submitForm() {
      this.editSmartHomeSetInfo()
    },

    editSmartHomeSetInfo() {
      this.ruleForm.deviceId = this.deviceData.deviceId
      this.ruleForm.terminal = 1
      this.ruleForm.unitUuid = session.getLoginInfo().unitUuid
      this.ruleForm.isDoor = this.isDoor
      this.ruleForm.deviceSubId = this.deviceData.deviceSubId,
      this.toEditData()
      editSmartHomeSetInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: "修改成功"});
          this.closeEdit();
          this.getSmartHomeSetInfo()
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    toEditData() {
      if(this.deviceData.switchOn == true) {
        this.ruleForm.switchOn = 1
      }else {
        this.ruleForm.switchOn = 0
      }


    },

    toData(data) {
      if(data.switchOn == 1) {
        this.deviceData.switchOn = true
      }else {
        this.deviceData.switchOn = false
      }

    },
    closeEdit() {
      this.edit = false
    },

    handleClick(tab) {
      if(0 == tab.index) {
        setTimeout(() => {
          this.$refs.guardianView.getParentData(this.ruleForm,this.deviceData);
        }, 200);
      }else if(1 == tab.index) {
        setTimeout(() => {
          this.$refs.alarmView.getParentData(this.ruleForm,this.deviceData);
        }, 200);
      }else if(2 == tab.index) {
        setTimeout(() => {
          this.$refs.noticeView.getParentData(this.deviceData);
        }, 200);
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 90%;
}
hr {
  border-color: #f4f4f526;
}
.title {
  margin-right: 10px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  // width: 75%;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}

</style>
<style lang="scss">
.setTab {
  .el-tabs__item {
    font-size:16px !important;
    // color: #676767;
    font-weight: 200
  }
} 

</style>