<template>
  <div class="page">
    <ls-title-header @back="cancel" title="设备详情"></ls-title-header>
    <div class="box">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="设备信息" name="first">
          <SmartHomeBaseInfo ref="baseView" @cancel="cancel"></SmartHomeBaseInfo>
        </el-tab-pane>
        <el-tab-pane label="功能设置" name="second">
          <SmartHomeSet v-if="this.deviceData.deviceSubId != 2" ref="setView" @cancel="cancel"></SmartHomeSet>
          <SmartHomeSosSet v-if="this.deviceData.deviceSubId == 2" ref="setSosView" @cancel="cancel"></SmartHomeSosSet>
        </el-tab-pane>
        <el-tab-pane label="使用记录" name="third">
          <SmartHomeRecord ref="recordView" @cancel="cancel"></SmartHomeRecord>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import SmartHomeBaseInfo from "./SmartHomeBaseInfo.vue";
import SmartHomeSet from "./SmartHomeSet.vue";
import SmartHomeRecord from "./SmartHomeRecord.vue";
import SmartHomeSosSet from "./SmartHomeSosSet.vue";
export default {
  components: {
    SmartHomeBaseInfo,
    SmartHomeSet,
    SmartHomeRecord,
    SmartHomeSosSet
  },
  data() {
    return {
      deviceData:{},
      activeName: "first",
    };
  },
  methods: {
    getParentData(data) {
      this.deviceData = data;
      setTimeout(() => {
        this.$refs.baseView.getParentData(data);
      }, 200);
    },
    cancel() {
      this.$emit("cancel");
    },
    handleClick(tab) {
      if(0 == tab.index) {
        setTimeout(() => {
          this.$refs.baseView.getParentData(this.deviceData);
        }, 200);
      }else if(1 == tab.index) {
        setTimeout(() => {
          if(this.deviceData.deviceSubId == 2) {
            this.$refs.setSosView.getParentData(this.deviceData);
          }else {
            this.$refs.setView.getParentData(this.deviceData);
          }
         
        }, 200);
      }else if(2 == tab.index) {
        setTimeout(() => {
          this.$refs.recordView.getParentData(this.deviceData);
        }, 200);
      }
    },
  },
};
</script>

<style scoped>
/* .page {
  padding: 40px 20px 40px 20px;
  background-color: white;
  border-radius: 4px;
} */
.page {
  padding: 0px 20px;
}
.box {
  /* width: 75%; */
  padding: 20px 20px;
  background-color: white;
  border-radius: 4px;
}
</style>
<style>
.el-tabs__item {
  font-size: 18px;
}
.el-tabs__nav-wrap::after {
  position: static !important;
}
</style>