<template>
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
    <div style="background-color: white">
      <div class="box">
        <el-row class="row">
          <el-col :span="20">
              <el-form-item prop="noticeContent">
                <template #label> <span class="title">AI语音</span></template>
                <!-- <el-input v-model="ruleForm.name1" class="input" ></el-input> onkeyup="value=value.replace(/[^\u4E00-\u9FA5]/g, '')" -->
                <el-input v-model="ruleForm.noticeContent"  placeholder="请输入AI语音描述(只支持中文)" maxlength="20" show-word-limit  class="input" clearable>
                    <template #append>
                      <el-button type="primary" style="color: white" @click="submitForm('ruleForm')">发送</el-button>
                    </template>
                    </el-input>
              </el-form-item>
          </el-col>
        </el-row>




<!-- 
        <el-row class="row">
          <el-col :span="12">
            <el-form-item>
              <el-button type="primary"  size="small"  @click="submitForm('ruleForm')">发送</el-button>
            </el-form-item>
          </el-col>
        </el-row> -->

      </div>
    </div>
  </el-form>

</template>

<script>
import {
  editSmartHomeSetInfo
} from '../../../api/api'
import session from "../../../store/store";
export default {
  data() {
    return {
      edit: false,
      role: session.getLoginInfo().accountType,
      ruleForm: {
      },
      rules: {
        noticeContent:[
          {required: true,min: 0,max: 11,message: "长度在 0 到 20 个汉字", trigger: "blur"},
          {pattern: /^[\u4E00-\u9FA5]+$/, message: "只能输入汉字", trigger: 'blur' }
        ],
      },
      deviceData:{}
    };
  },
  mounted() {
  },
  methods: {
    getParentData(deviceData) {
      this.deviceData = deviceData
    },

    
    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          this.editSmartHomeSetInfo();
        } else {
          return false;
        }
      });
    },

    editSmartHomeSetInfo() {
      this.ruleForm.deviceId = this.deviceData.deviceId
      this.ruleForm.terminal = 1
      this.ruleForm.unitUuid = session.getLoginInfo().unitUuid
      this.ruleForm.isDoor = 0
      this.ruleForm.deviceSubId = this.deviceData.deviceSubId,
      this.ruleForm.func = 'notice'
      editSmartHomeSetInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: '发送成功'});
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },


  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 90%;
}
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.title {
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  width: 75%;
  // padding: 20px 20px;
  background-color: white;
  border-radius: 4px;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}


</style>

